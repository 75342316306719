import * as React from "react"
import { SEO, Rowers } from "../components"
import Header from "../components/Layout/Header"

const RowersPage: React.FC<{}> = () => (
  <div>
    <SEO title="Rowers" />
    <Header />
    <Rowers />
  </div>
)

export default RowersPage
